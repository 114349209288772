import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-json-ld',
    templateUrl: './json-ld.component.html',

    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class JsonLdComponent implements OnChanges {
    @Input() json: any;
    @HostBinding('innerHTML') jsonLD: SafeHtml;
    constructor(private sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges) {
        this.jsonLD = this.getSafeHTML(changes.json.currentValue);
    }

    getSafeHTML(value: {}) {
        const json = value
            ? JSON.stringify(value, null, 2).replace(
                  /<\/script>/g,
                  '<\\/script>'
              )
            : '';
        const html = `<script type="application/ld+json">${json}</script>`;
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
